import React from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash.kebabcase';
import { register } from 'timeago.js';

import Layout from '../components/Layout';
import HoodDetailView from '../components/HoodDetailView';

import { getFlagEmoji, deLocaleFunc } from '../utils/locale-helper';

register('de', deLocaleFunc);

const Hood = ({ pageContext }) => {
  return (
    <Layout
      pageTitle={`${pageContext.hoodTitle}, ${
        pageContext.cityTitle
      } ${getFlagEmoji(pageContext.countryCode)}`}
      pageImage={`/img/${kebabCase(pageContext.cityTitle)}.jpg`}
      slug={`${pageContext.parentUrl}${kebabCase(pageContext.hoodTitle)}/`}
    >
      <HoodDetailView {...pageContext} />
    </Layout>
  );
};

Hood.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Hood;
