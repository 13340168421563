import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

import ReviewsListItem from '../ReviewsListItem';
import UserMessage from '../UserMessage';
import { USER_REVIEW_SECTION_ID } from '../UserReview/UserReview';
import Skeleton from '../Skeleton';

import { fetchReviews } from '../../state/reviews/actions';
import useComponentDidMount from '../../hooks/use-component-did-mount';
import { useAuth0 } from '../../utils/auth';

import style from './ReviewsList.module.css';

const makeMapStateToPropsSelector = () =>
  createSelector(
    (state) => state.reviewsReducer,
    (_, cityTitle, hoodTitle, user) => ({ cityTitle, hoodTitle, user }),
    (reviewsData, { cityTitle, hoodTitle, user }) => {
      const reviewsByCity = reviewsData[cityTitle] || {};
      const { reviews = [], loaded, error } = reviewsByCity[hoodTitle] || {};

      const reviewsFromOtherUsers = user
        ? reviews.filter((review) => review.authorAuthId !== user.sub) || []
        : reviews;

      return {
        reviews: reviewsFromOtherUsers,
        hasCurrentUserReview: reviewsFromOtherUsers.length < reviews.length,
        error,
        loaded,
      };
    }
  );

const ReviewsList = ({ cityTitle, hoodTitle }) => {
  const { user } = useAuth0();

  const selectDataFromState = useMemo(makeMapStateToPropsSelector, []);
  const {
    reviews,
    loaded,
    error,
    hasCurrentUserReview,
  } = useSelector((state) =>
    selectDataFromState(state, cityTitle, hoodTitle, user)
  );

  const dispatch = useDispatch();
  const loadReviews = () => dispatch(fetchReviews({ cityTitle, hoodTitle }));

  useComponentDidMount(() => {
    if (!loaded) {
      loadReviews();
    }
  });

  if (!loaded) {
    return (
      <div className={style.container}>
        <Skeleton className={style.titleSkeleton} />
        <Skeleton className={style.bodySkeleton} />
        <Skeleton className={style.bodySkeleton} />
      </div>
    );
  }

  const scrollToReviewForm = () =>
    document.getElementById(USER_REVIEW_SECTION_ID).scrollIntoView({
      behavior: 'smooth',
    });

  const noReviewsFlag = !reviews.length && !hasCurrentUserReview;

  const getContent = () => {
    if (error === 'NotFoundError' || noReviewsFlag) {
      return (
        <UserMessage
          type="noReviews"
          values={{ hoodTitle }}
          onClick={scrollToReviewForm}
        />
      );
    } else if (error) {
      return <UserMessage type="reviewsLoadError" onClick={loadReviews} />;
    } else {
      return reviews.map((review, i) => (
        <ReviewsListItem
          {...review}
          cityTitle={cityTitle}
          key={`${review.createdAt}-${i}`}
        />
      ));
    }
  };

  return (
    <div className={style.container}>
      {error || noReviewsFlag || reviews.length ? (
        <>
          <h3>
            <FormattedHTMLMessage
              id="main.reviewsList.title"
              values={{
                hoodTitle,
              }}
            />
          </h3>
          <div className={style.list}>{getContent()}</div>
        </>
      ) : null}
    </div>
  );
};

ReviewsList.propTypes = {
  hoodTitle: PropTypes.string.isRequired,
  cityTitle: PropTypes.string.isRequired,
};

export default ReviewsList;
