import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'gatsby-plugin-intl';

import TextLink from '../TextLink';
import ResponsiveImage from '../ResponsiveImage';
import HoodNumbers from '../HoodNumbers';
import RatingList from '../RatingList';
import UserReview from '../UserReview';
import TotalRating from '../TotalRating';
import ReviewsList from '../ReviewsList';

import useWindowSize from '../../hooks/use-window-size';

import style from './HoodDetailView.module.css';

const HoodDetailView = ({
  hoodTitle,
  hoodImageUrl,
  districtName,
  population,
  rentPrice,
  cityTitle,
  countryCode,
  area,
  cityPopulation,
  cityDensity,
  cityRentPrice,
  parentUrl,
}) => {
  const { width } = useWindowSize();
  const imgHeight = width > 992 ? 400 : 250;

  return (
    <section className={style.container}>
      <TextLink
        linkTo={parentUrl}
        titleKey="main.general.backButton"
        className={style.backButton}
      />
      <div className={style.titleBlock}>
        <div className={style.titles}>
          <h1 className={style.title}>{hoodTitle}</h1>
          <h3>
            {districtName},{' '}
            <FormattedMessage id={`cities.${cityTitle}.title`} />
          </h3>
        </div>
        <TotalRating cityTitle={cityTitle} hoodTitle={hoodTitle} />
      </div>
      <ResponsiveImage
        src={hoodImageUrl}
        height={imgHeight}
        alt="hood"
        className={style.image}
      />
      <HoodNumbers
        cityTitle={cityTitle}
        hoodTitle={hoodTitle}
        countryCode={countryCode}
        population={population}
        rentPrice={rentPrice}
        area={area}
        cityPopulation={cityPopulation}
        cityDensity={cityDensity}
        cityRentPrice={cityRentPrice}
      />
      <RatingList cityTitle={cityTitle} hoodTitle={hoodTitle} />
      <UserReview cityTitle={cityTitle} hoodTitle={hoodTitle} />
      <ReviewsList cityTitle={cityTitle} hoodTitle={hoodTitle} />
    </section>
  );
};

HoodDetailView.propTypes = {
  hoodTitle: PropTypes.string.isRequired,
  hoodImageUrl: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  districtName: PropTypes.string.isRequired,
  cityTitle: PropTypes.string.isRequired,
  parentUrl: PropTypes.string.isRequired,
  population: PropTypes.array,
  rentPrice: PropTypes.array,
  cityPopulation: PropTypes.number.isRequired,
  cityDensity: PropTypes.number.isRequired,
  cityRentPrice: PropTypes.number.isRequired,
};

export default HoodDetailView;
